import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import ReactTable from 'react-table-v6';
import { Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { Edit } from 'react-feather';
import 'react-table-v6/react-table.css';
import ComponentCard from '../../components/ComponentCard';

const ProjectAdmin = () => {
  const [modal, setModal] = useState(false);
  const [obj, setObj] = useState({});
  const [projects, setProjects] = useState([]);
  const user = JSON.parse(localStorage.getItem('user'));
  const isAdmin = user?.type === 'admin' || user?.type === 'management';
  const toggle = () => {
    setModal(!modal);
  };

  const navigate = useNavigate();

  const fetchProjects = async () => {
    try {
      const response = await axios.get('https://ooh.warburttons.com/api/site', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setProjects(response.data.data);
    } catch (error) {
      console.log('Error fetching projects:', error);
    }
  };

  // Fetch projects from API when the component mounts
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const token = user?.token;
    console.log(storedUser, 'kk');
    if (!token) {
      navigate('/auth/loginformik');
      return;
    }

    fetchProjects();
  }, []);

  const baseUrl = 'https://ooh.warburttons.com/';
  const exportToExcel = () => {
    const exportData = projects.map((project) => ({
      location: project.location,
      site_code: project.site_code,

      city: project.city,
      district: project.district,
      state: project.state,
      zone: project.zone,
      vendor: project.vendor,
      height: project.height,
      width: project.width,
      total: project.total,
      signature: project.signature,
      racce_person_name: project.racce_person_name,
      racce_person_image: {
        f: `HYPERLINK("${baseUrl}${project.racce_person_image}", "${baseUrl}${project.racce_person_image}")`,
      },

      image1: { f: `HYPERLINK("${baseUrl}${project.image1}", "${baseUrl}${project.image1}")` },
      image2: { f: `HYPERLINK("${baseUrl}${project.image2}", "${baseUrl}${project.image2}")` },
      image3: { f: `HYPERLINK("${baseUrl}${project.image3}", "${baseUrl}${project.image3}")` },
      image4: { f: `HYPERLINK("${baseUrl}${project.image4}", "${baseUrl}${project.image4}")` },
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Admin Project ');

    XLSX.writeFile(workbook, 'projects_data.xlsx');
  };
  const defaultFilterMethod = (filter, row) => {
    return String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase());
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    formData.append('code', event.target.code.value);
    formData.append('site_name', event.target.site.value);
    formData.append('state', event.target.state.value);
    formData.append('district', event.target.district.value);
    formData.append('city', event.target.city.value);
    formData.append('zone', event.target.zone.value);
    formData.append('lat', event.target.lat.value);
    formData.append('long', event.target.long.value);
    formData.append('length', event.target.length.value);
    formData.append('width', event.target.width.value);
    formData.append('total', event.target.total.value);
    // formData.append('date', event.target.date.value);
    formData.append('remarks', event.target.remarks.value);
    formData.append('created_by', user.id);
    formData.append('any_damage', event.target.anydamage.value);
    formData.append('vendor_name', event.target.vendorname.value);
    formData.append('image_last_updated_date', event.target.imageupdate.value);
    formData.append('location', event.target.location.value);

    if (event.target.image.files[0]) {
      formData.append('image', event.target.image.files[0]);
    }
    if (event.target.image1.files[0]) {
      formData.append('image1', event.target.image1.files[0]);
    }
    if (event.target.image2.files[0]) {
      formData.append('image2', event.target.image2.files[0]);
    }
    if (event.target.image3.files[0]) {
      formData.append('image3', event.target.image3.files[0]);
    }
    if (event.target.image4.files[0]) {
      formData.append('image4', event.target.image4.files[0]);
    }

    try {
      const { id } = obj;
      const response = await axios.post(`https://ooh.warburttons.com/api/site`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', 
        },
      });

      setProjects((prevProjects) =>
        prevProjects.map((proj) => (proj.id === id ? { ...proj, ...formData } : proj)),
      );

      console.log('Project updated successfully!', response);
    } catch (error) {
      console.error('Error updating project:', error);
    }

    setModal(false);
  };
console.log(projects,'ddddddddddddd')
  const tableData = useMemo(() => {
    return projects.map((prop) => ({
      ...prop,
      site_code: (
        <Link
          to={`/project-admin-details/${prop.site_code.replace('_', '')}`}
          style={{ textDecoration: 'none', color: '#343a40' }}
        >
          {prop.site_code}
        </Link>
      ),

      location: (
        <Link
          to={`/project-admin-details/${prop.site_code.replace('_', '')}`}
          style={{ textDecoration: 'none', color: '#343a40' }}
        >
          {prop.location}
        </Link>
      ),
      vendor: (
        <Link
          to={`/vendor-details/${prop.vendor}`}
          style={{ textDecoration: 'none', color: '#343a40' }}
        >
          {prop.vendor}
        </Link>
      ),
      actions: isAdmin ? (
        <div className="text-center">
          <Button
            onClick={() => {
              setObj({ id: prop.id, ...prop });
              toggle();
            }}
            color="inverse"
            size="sm"
          >
            <i className="fa fa-edit" /> <Edit />
          </Button>
        </div>
      ) : null,
    }));
  }, [projects, isAdmin]);

  return (
    <div>
      <div className="d-flex justify-content-end">
        <Button color="success mb-2 " onClick={exportToExcel}>
          Export to Excel
        </Button>
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Edit Project</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            {/* <FormGroup>
              <Label for="project">Project </Label>
              <Input type="text" name="project" id="project" defaultValue={obj.project} />
            </FormGroup> */}
            <FormGroup>
              <Label for="code">Code</Label>
              <Input type="text" name="code" id="code" defaultValue={obj.site_code} />
            </FormGroup>

            <FormGroup>
              <Label for="site">Site Name </Label>
              <Input type="text" name="site" id="site" defaultValue={obj.location} />
            </FormGroup>
            <FormGroup>
              <Label for="city">City</Label>
              <Input type="text" name="city" id="city" defaultValue={obj.city} />
            </FormGroup>
            <FormGroup>
              <Label for="district">District</Label>
              <Input type="text" name="district" id="district" defaultValue={obj.district} />
            </FormGroup>
            <FormGroup>
              <Label for="state">State</Label>
              <Input type="text" name="state" id="state" defaultValue={obj.state} />
            </FormGroup>
            <FormGroup>
              <Label for="zone">Zone</Label>
              <Input type="text" name="zone" id="zone" defaultValue={obj.zone} />
            </FormGroup>

            {/* <FormGroup>
              <Label for="area">Area</Label>
              <Input type="text" name="area" id="area" defaultValue={obj.area} />
            </FormGroup> */}

            <FormGroup>
              <Label for="lat">Lat</Label>
              <Input type="text" name="lat" id="lat" defaultValue={obj.lat} />
            </FormGroup>
            <FormGroup>
              <Label for="long">Long</Label>
              <Input type="text" name="long" id="long" defaultValue={obj.long} />
            </FormGroup>

            {/* <FormGroup>
              <Label for="address">Address</Label>
              <Input type="text" name="address" id="address" defaultValue={obj.address} />
            </FormGroup> */}

            <FormGroup>
              <Label for="length">Length </Label>
              <Input type="text" name="length" id="length" defaultValue={obj.height} />
            </FormGroup>

            <FormGroup>
              <Label for="width">Width</Label>
              <Input type="text" name="width" id="width" defaultValue={obj.width} />
            </FormGroup>
            <FormGroup>
              <Label for="total">Total</Label>
              <Input type="text" name="total" id="total" defaultValue={obj.total} />
            </FormGroup>
            {/* <FormGroup>
              <Label for="date">Date</Label>
              <Input type="date" name="date" id="date" defaultValue={obj.date} />
            </FormGroup> */}

            {/* <FormGroup>
              <Label for="asmname">ASM Name</Label>
              <Input type="text" name="asmname" id="asmname" defaultValue={obj.asm_name} />
            </FormGroup> */}
            {/* <FormGroup>
              <Label for="zo">Zo Name</Label>
              <Input type="text" name="zo" id="zo" defaultValue={obj.zo_name} />
            </FormGroup> */}
            <FormGroup>
              <Label for="remarks">Remarks</Label>
              <Input type="text" name="remarks" id="remarks" defaultValue={obj.remarks} />
            </FormGroup>
            {/* <FormGroup>
              <Label for="status">Status </Label>
              <Input type="text" name="status" id="status" defaultValue={obj.status} />
            </FormGroup> */}
            {/* <FormGroup>
              <Label for="createdby">Created By</Label>
              <Input type="text" name="createdby" id="createdby" defaultValue={user.id} />
            </FormGroup> */}
            <FormGroup>
              <Label for="anydamage">Any Damage</Label>
              <Input type="text" name="anydamage" id="anydamage" defaultValue={obj.any_damage} />
            </FormGroup>
            {/* <FormGroup>
              <Label for="vendorid">Vendor Id </Label>
              <Input type="text" name="vendorid" id="vendorid" defaultValue={obj.vendor_id} />
            </FormGroup> */}

            <FormGroup>
              <Label for="vendorname">Vendor Name </Label>
              <Input type="text" name="vendorname" id="vendorname" defaultValue={obj.vendor} />
            </FormGroup>

            <FormGroup>
              <Label for="imageupdate">Image Last Updated Date </Label>
              <Input
                type="date"
                name="imageupdate"
                id="imageupdate"
                defaultValue={obj.image_last_updated_date}
              />
            </FormGroup>
            <FormGroup>
              <Label for="location">Location </Label>
              <Input type="text" name="location" id="location" defaultValue={obj.location} />
            </FormGroup>
            {/* <FormGroup>
              <Label for="newracceperson">New Racce Person</Label>
              <Input
                type="text"
                name="newracceperson"
                id="newracceperson"
                defaultValue={obj.new_racce_person_name}
              />
            </FormGroup>

            <FormGroup>
              <Label for="raccepersonimage">Racce Person Image</Label>
              <Input
                type="file"
                name="raccepersonimage"
                id="raccepersonimage"
                defaultValue={obj.racce_person_image}
              />
            </FormGroup> */}

            <FormGroup>
              <Label for="image">Image</Label>
              <Input
                type="file"
                name="image"
                id="image"
                onChange={(e) => setObj({ ...obj, image: e.target.files[0] })}
                defaultValue={obj.image}
              />
            </FormGroup>

            <FormGroup>
              <Label for="image1">Image1</Label>
              <Input type="file" name="image1" id="image1" defaultValue={obj.image1} />
            </FormGroup>

            <FormGroup>
              <Label for="image2">Image2</Label>
              <Input type="file" name="image2" id="image2" defaultValue={obj.image2} />
            </FormGroup>

            <FormGroup>
              <Label for="image3">Image3</Label>
              <Input type="file" name="image3" id="image3" defaultValue={obj.image3} />
            </FormGroup>
            <FormGroup>
              <Label for="image4">Image4</Label>
              <Input type="file" name="image4" id="image4" defaultValue={obj.image4} />
            </FormGroup>

            <FormGroup>
              <Button color="primary" type="submit">
                Save
              </Button>
              <Button color="secondary" className="ml-1" onClick={toggle}>
                Cancel
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>

      <ComponentCard title="Projects Table">
        <ReactTable
          data={tableData}
          columns={[
            // {
            //   Header: 'ID',
            //   accessor: 'id',
            //   filterMethod: (filter, row) => {
            //     return row[filter.id].toString().toLowerCase().includes(filter.value.toLowerCase());
            //   },
            // },

            {
              Header: 'Site Name',
              accessor: 'location',
              filterMethod: (filter, row) => {
                // Handle case where row[filter.id] is a component
                const rowValue =
                  typeof row[filter.id] === 'string'
                    ? row[filter.id]
                    : row[filter.id]?.props?.children || '';

                return rowValue.toLowerCase().includes(filter.value.toLowerCase());
              },
            },
            {
              Header: 'Code',
              accessor: 'site_code',
              filterMethod: (filter, row) => {
                const rowValue = row[filter.id].props.children;
                return rowValue.toLowerCase().includes(filter.value.toLowerCase());
              },
            },
            // {
            //   Header: 'Project',
            //   accessor: 'project',
            //   filterMethod: (filter, row) => {
            //     return row[filter.id].toString().toLowerCase().includes(filter.value.toLowerCase());
            //   },
            // },
            {
              Header: 'City',
              accessor: 'city',
            },
            // {
            //   Header: 'District',
            //   accessor: 'district',
            // },
            {
              Header: 'State',
              accessor: 'state',
            },
            {
              Header: 'Zone',
              accessor: 'zone',
            },
            {
              Header: 'Vendor Name',
              accessor: 'vendor',
              filterMethod: (filter, row) => {
                // Handle case where row[filter.id] is a component
                const rowValue =
                  typeof row[filter.id] === 'string'
                    ? row[filter.id]
                    : row[filter.id]?.props?.children || '';

                return rowValue.toLowerCase().includes(filter.value.toLowerCase());
              },
            },
            {
              Header: 'Height',
              accessor: 'height',
            },
            {
              Header: 'Width',
              accessor: 'width',
            },
            {
              Header: 'Total',
              accessor: 'total',
            },
            { Header: 'Actions', accessor: 'actions', sortable: false, filterable: false },
          ]}
          defaultPageSize={10}
          pageSizeOptions={[10, 20, 100, 200, 500, 2500]}
          showPaginationBottom
          className="-striped -highlight"
          filterable
          defaultFilterMethod={defaultFilterMethod}
        />
      </ComponentCard>
    </div>
  );
};

export default ProjectAdmin;
