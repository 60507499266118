import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { Carousel, Modal } from 'react-bootstrap';
import axios from 'axios';

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)', // Semi-transparent background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000, // Ensures the modal is above other content
  },
  modal: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '5px',
    position: 'relative',
    maxWidth: '600px',
    width: '100%',
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  closeButton: {
    cursor: 'pointer',
    border: 'none',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    margin: '0',
    background: 'transparent',
  },
};
const ProjectDetails = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [showCarousel, setShowCarousel] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage('');
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`https://ooh.warburttons.com/api/project/${id}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setUser(response.data.data);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUser();
  }, [id]);

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setShowCarousel(true);
  };
  const baseUrl = 'https://ooh.warburttons.com/';

  const handleCloseCarousel = () => setShowCarousel(false);
  // Export Table Data to Excel
  const exportToExcel = () => {
    const tableData = [
      ['Name', user.project],
      ['Lat', user.lat],
      ['Long', user.long],
      ['Length', user.length],
      ['Width', user.width],
      ['Total', user.total],
      ['Code', user.code],
      ['Phone Number', user.mobile],
      ['Area', user.status],
      ['Zone', user.zone],
      ['State', user.state],
      ['District', user.vendor_status],
      ['City', user.city],
      ['ASM Name', user.asm_name],
      ['ZO Name', user.zo_name],
      ['Recce Person Name', user.racce_person_name],
      [
        'Recce Person Image',
        {
          f: `HYPERLINK("${baseUrl}${user.racce_person_image}", "${baseUrl}${user.racce_person_image}")`,
        },
      ],

      ['Image1', { f: `HYPERLINK("${baseUrl}${user.image1}", "${baseUrl}${user.image1}")` }],
      ['Image2', { f: `HYPERLINK("${baseUrl}${user.image2}", "${baseUrl}${user.image2}")` }],
      ['Image3', { f: `HYPERLINK("${baseUrl}${user.image3}", "${baseUrl}${user.image3}")` }],
      ['Image4', { f: `HYPERLINK("${baseUrl}${user.image4}", "${baseUrl}${user.image4}")` }],
    ];

    const ws = XLSX.utils.aoa_to_sheet(tableData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Project Details');
    XLSX.writeFile(wb, 'Project_Details.xlsx');
  };

  // Print the Table Data
  const printTable = () => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
      <head>
        <title>Print Table</title>
        <style>
          @page {
            margin: 0;
          }
          body {
            margin: 1cm; 
          }
            body::before {
            content: 'Dalmia Cement';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            font-size: 80px;
            color: rgba(0, 0, 0, 0.1);
            z-index: -1;
            white-space: nowrap;
          }
          table {
            width: 100%; 
            border-collapse: collapse;
          }
          th, td {
            border: 1px solid #ddd; 
            padding: 8px;
          }
          th {
            background-color: #f2f2f2;
          }
          h1 {
            text-align: center;
          }
                .no-border {
            border: none;
          }
        </style>
      </head>
        <body>
          <h4>Site Name : ${user.site_name}</h4>
          <h4>Code : ${user.code}</h4>
          <table>
            <tbody>
              <tr>
                    <td><strong>Zone</strong></td>
                <td>${user.zone}</td>
               
                <td><strong>City</strong></td>
                <td>${user.city}</td>
                  <td><strong>Location</strong></td>
                <td>${user.location}</td>
              </tr>
              <tr>
             
              
                <td><strong>State</strong></td>
                <td>${user.state}</td>
                  <td><strong>Status</strong></td>
                <td>${user.status}</td>
                  <td><strong>Vendor Status</strong></td>
                <td>${user.vendor_status}</td>
              </tr>
              <tr>
                <td><strong>Length</strong></td>
                <td>${user.length}</td>
                 <td><strong>Width</strong></td>
                <td>${user.width}</td>
                <td><strong>Total</strong></td>
                <td>${user.total}</td>
               
              </tr>
              <tr>
                <td><strong>Lat</strong></td>
                <td>${user.lat}</td>
                <td><strong>Long</strong></td>
                <td>${user.long}</td>
                <td><strong>Vendor Name</strong></td>
                <td>${user.vendor_name}</td>
             
              </tr>
            
              <tr>
                <td width="200">
                  <h6>Recce Person Name </h6>
                </td>
                <td>: ${user.racce_person_name}</td>

                <td width="200">
                  <h6>Recce Person Image </h6>
                </td>
                <td>
                  :  <img src="https://ooh.warburttons.com/${
                    user.racce_person_image
                  }" alt="" width="100" height="100" />
                </td>
              </tr>

              <tr>
                <td class="no-border">
                  <img src="https://ooh.warburttons.com/${
                    user.image
                  }" alt="" width="100" height="100" />
                </td>
                <td class="no-border">
                  <img src="https://ooh.warburttons.com/${
                    user.image1
                  }" alt="" width="100" height="100" />
                </td>
             
                <td class="no-border">
                  <img src="https://ooh.warburttons.com/${
                    user.image2
                  }" alt="" width="100" height="100" />
                </td>
                  <td class="no-border">
                  <img src="https://ooh.warburttons.com/${
                    user.image3
                  }" alt="" width="100" height="100" />
                </td>
                  <td class="no-border">
                  <img src="https://ooh.warburttons.com/${
                    user.image4
                  }" alt="" width="100" height="100" />
                </td>

                
              </tr>
              <tr> <td >Image Updated Date :</td>
                  <td >
                 
                    ${new Date(
                      new Date(user.image_last_updated_date).getTime() + 5.5 * 60 * 60 * 1000,
                    ).toLocaleDateString('en-IN', { timeZone: 'Asia/Kolkata' })}
                  </td></tr>
            </tbody>
          </table>
        </body>
      </html>
    `);
    printWindow.onload = () => {
      const { images } = printWindow.document; // Use object destructuring here
      const totalImages = images.length;
      let loadedImages = 0;

      const imageLoaded = () => {
        loadedImages++;
        if (loadedImages === totalImages) {
          printWindow.print();
        }
      };

      for (let i = 0; i < totalImages; i++) {
        images[i].onload = imageLoaded;
        images[i].onerror = imageLoaded; // In case of error, count as loaded
      }

      if (totalImages === 0) {
        printWindow.print();
      }
    };
    printWindow.document.close();
    printWindow.print();
  };

  // history data print and excel
  const exportHistoriesToExcel = () => {
    const historiesData = user.histories.map((item) => [
      item.name,
      item.lat,
      item.long,
      item.length,
      item.width,
      item.total,
      item.code,
      item.mobile || 'N/A',
      item.area || 'N/A',
      item.zone,
      item.state,
      item.district,
      item.city,
      item.racce_person_name,
      `${baseUrl}${item.racce_person_image}` || 'N/A',
      item.asm_name || 'N/A',
      item.zo_name || 'N/A',
      `${baseUrl}${item.image1}` || 'N/A',
      `${baseUrl}${item.image2}` || 'N/A',
      `${baseUrl}${item.image3}` || 'N/A',
      `${baseUrl}${item.image4}` || 'N/A',
    ]);

    // Include headers
    const headers = [
      'Name',
      'Lat',
      'Long',
      'Length',
      'Width',
      'Total',
      'Code',
      'Phone Number',
      'Area',
      'Zone',
      'State',
      'District',
      'City',
      'ASM Name',
      'ZO Name',
      'Recce Person Name',
      'Recce Person Image',
      'Image1',
      'Image2',
      'Image3',
      'Image4',
    ];

    const tableData = [headers, ...historiesData];

    const ws = XLSX.utils.aoa_to_sheet(tableData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Histories');
    XLSX.writeFile(wb, 'Project_Histories.xlsx');
  };

  const printTables = () => {
    const printWindow = window.open('', '_blank');

    const historyRows = user.histories
      .map(
        (item) => `
      <html>
      <head>
        <title>Print Table</title>
        <style>
          @page {
            margin: 0; /* Removes the default header and footer */
          }
          body {
            margin: 1cm; 
      }
             body::before {
            content: 'Dalmia Cement';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            font-size: 80px;
            color: rgba(0, 0, 0, 0.1);
            z-index: -1;
            white-space: nowrap;
          }
          table {
            width: 100%; 
            border-collapse: collapse;
          }
          th, td {
            border: 1px solid #ddd; 
            padding: 8px;
          }
          th {
            background-color: #f2f2f2;
          }
          h1 {
            text-align: center;
          }
              .no-border {
            border: none;
          }
        </style>
      </head>
        <body>
          <h4>Site Name : ${item.site_name}</h4>
          <h4>Code : ${item.code}</h4>
          <table>
            <tbody>
                 <tr>
                    <td><strong>Zone</strong></td>
                <td>${item.zone}</td>
               
                <td><strong>City</strong></td>
                <td>${item.city}</td>
                  <td><strong>Location</strong></td>
                <td>${item.location}</td>
              </tr>
              <tr>
             
              
                <td><strong>State</strong></td>
                <td>${item.state}</td>
                  <td><strong>Status</strong></td>
                <td>${item.status}</td>
                  <td><strong>Vendor Status</strong></td>
                <td>${item.vendor_status}</td>
              </tr>
              <tr>
                <td><strong>Length</strong></td>
                <td>${item.length}</td>
                 <td><strong>Width</strong></td>
                <td>${item.width}</td>
                <td><strong>Total</strong></td>
                <td>${item.total}</td>
               
              </tr>
              <tr>
                <td><strong>Lat</strong></td>
                <td>${item.lat}</td>
                <td><strong>Long</strong></td>
                <td>${item.long}</td>
                 <td><strong>Vendor Name</strong></td>
                <td>${item.vendor_name}</td>
             
              </tr>
            
              <tr>
                <td width="200">
                  <h6>Recce Person Name </h6>
                </td>
                <td> ${item.racce_person_name}</td>

                <td width="200">
                  <h6>Recce Person Image   </h6>
                </td>
                <td>
                    <img src="https://ooh.warburttons.com/${
                      item.racce_person_image
                    }" alt="" width="100" height="100" />
                </td>
              </tr>


              <tr>
                <td  class="no-border"">
                  <img src="https://ooh.warburttons.com/${
                    item.image
                  }" alt="" width="100" height="100" />
                </td>
                <td class="no-border">
                  <img src="https://ooh.warburttons.com/${
                    item.image1
                  }" alt="" width="100" height="100" />
                </td>
             
                <td class="no-border">
                  <img src="https://ooh.warburttons.com/${
                    item.image2
                  }" alt="" width="100" height="100" />
                </td>
                  <td class="no-border">
                  <img src="https://ooh.warburttons.com/${
                    item.image3
                  }" alt="" width="100" height="100" />
                </td>
                  <td class="no-border">
                  <img src="https://ooh.warburttons.com/${
                    item.image4
                  }" alt="" width="100" height="100" />
                </td>

                
              </tr>
              <tr> <td >Image Updated Date :</td>
                  <td >
                 
                    ${new Date(
                      new Date(item.image_last_updated_date).getTime() + 5.5 * 60 * 60 * 1000,
                    ).toLocaleDateString('en-IN', { timeZone: 'Asia/Kolkata' })}
                  </td></tr>
            </tbody>
          </table>
        </body>
      </html>
    `,
      )
      .join('');

    printWindow.onload = () => {
      const { images } = printWindow.document; // Use object destructuring here
      const totalImages = images.length;
      let loadedImages = 0;

      const imageLoaded = () => {
        loadedImages++;
        if (loadedImages === totalImages) {
          printWindow.print();
        }
      };

      for (let i = 0; i < totalImages; i++) {
        images[i].onload = imageLoaded;
        images[i].onerror = imageLoaded; // In case of error, count as loaded
      }

      if (totalImages === 0) {
        printWindow.print();
      }
    };
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Table</title>
          <style>
            table { width: 100%; border-collapse: collapse; }
            th, td { border: 1px solid #ddd; padding: 8px; }
            th { background-color: #f2f2f2; }
            h1 { text-align: center; }
          </style>
        </head>
        <body>
      
          <table>
           
              ${historyRows}
           
          </table>
        </body>
      </html>
    `);

    printWindow.document.close();
    printWindow.print();
  };

  if (!user) return <div>Loading...</div>;

  return (
    <>
      <div style={{ background: '#fff', borderRadius: '10px' }}>
        <div className="p-4">
          <div className="d-flex flex justify-content-between">
            <div>
              <h4>
                <span>Site Name :</span> {user.site_name}
              </h4>
              <h4>
                <span>Code :</span> {user.code}
              </h4>
            </div>
            <div>
              <button type="button" onClick={exportToExcel} className="btn btn-primary mb-3 me-2">
                Export to Excel
              </button>
              <button type="button" onClick={printTable} className="btn btn-secondary mb-3">
                Print Table
              </button>
            </div>
          </div>
          <table className="table ">
            <tbody>
              <tr>
                <td width="200">
                  <h6>Zone </h6>
                </td>
                <td>: {user.zone}</td>
                
                <td width="200">
                  <h6>City </h6>
                </td>
                <td>: {user.city}</td>

                <td width="200">
                  <h6>Location </h6>
                </td>
                <td width="300">: {user.location}</td>
              </tr>

              <tr>
                

               
                <td width="200">
                  <h6>State </h6>
                </td>
                <td>: {user.state}</td>

                <td width="200">
                  <h6>Status </h6>
                </td>
                <td>: {user.status}</td>

                <td width="200">
                  <h6>Vendor Status </h6>
                </td>
                <td>: {user.vendor_status}</td>
              </tr>

              <tr>
                <td width="200">
                  <h6>Length </h6>
                </td>
                <td>: {user.length}</td>
                <td width="200">
                  <h6>Width </h6>
                </td>
                <td>: {user.width}</td>
                <td width="200">
                  <h6>Total </h6>
                </td>
                <td>: {user.total}</td>
              </tr>

              <tr>
                <td width="200">
                  <h6>Lat </h6>
                </td>
                <td>: {user.lat}</td>
                <td width="200">
                  <h6>Long </h6>
                </td>
                <td>: {user.long}</td>

                <td width="200">
                  <h6>Vendor Name </h6>
                </td>
                <td>: {user.vendor_name}</td>
              </tr>

              <tr>
                <td width="200">
                  <h6>Recce Person Name </h6>
                </td>
                <td>: {user.racce_person_name}</td>

                <td width="200">
                  <h6>Recce Person Image </h6>
                </td>
                <td>
                  :{' '}
                  <button
                    type="button"
                    onClick={() =>
                      openModal(`https://ooh.warburttons.com/${user.racce_person_image}`)
                    }
                    style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }} // Optional styling
                  >
                    <img
                      src={`https://ooh.warburttons.com/${user.racce_person_image}`}
                      alt="Person"
                      width={100}
                      height={100}
                      onError={(e) => {
                        e.target.style.display = 'none'; // Hide the broken image
                        e.target.insertAdjacentHTML('afterend', '<span>NA</span>'); // Insert "NA" next to the image
                      }}
                    />
                  </button>
                </td>
              </tr>

              <tr>
                <td width="200" className="d-flex gap-2">
                  {user.image ? (
                    <button
                      type="button"
                      style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
                      onClick={() => handleImageClick(0)}
                    >
                      <img
                        src={`https://ooh.warburttons.com/${user.image}`}
                        alt=""
                        width={100}
                        height={100}
                      />
                    </button>
                  ) : (
                    ''
                  )}

                  {user.image1 ? (
                    <button
                      type="button"
                      style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
                      onClick={() => handleImageClick(1)}
                    >
                      <img
                        src={`https://ooh.warburttons.com/${user.image1}`}
                        alt=""
                        width={100}
                        height={100}
                      />
                    </button>
                  ) : (
                    ''
                  )}
                  {user.image2 ? (
                    <button
                      type="button"
                      style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
                      onClick={() => handleImageClick(2)}
                    >
                      <img
                        src={`https://ooh.warburttons.com/${user.image2}`}
                        alt=""
                        width={100}
                        height={100}
                      />
                    </button>
                  ) : (
                    ' '
                  )}
                  {user.image3 ? (
                    <button
                      type="button"
                      style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
                      onClick={() => handleImageClick(3)}
                    >
                      <img
                        src={`https://ooh.warburttons.com/${user.image3}`}
                        alt=""
                        width={100}
                        height={100}
                      />
                    </button>
                  ) : (
                    ''
                  )}

                  {user.image4 ? (
                    <button
                      type="button"
                      style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
                      onClick={() => handleImageClick(4)}
                    >
                      <img
                        src={`https://ooh.warburttons.com/${user.image4}`}
                        alt=""
                        width={100}
                        height={100}
                      />
                    </button>
                  ) : (
                    ''
                  )}
                  <td style={{ minWidth: '230px' }}>Image Updated Date :</td>
                  <td style={{ minWidth: '230px' }}>
                    {' '}
                    {new Date(
                      new Date(user.image_last_updated_date).getTime() + 5.5 * 60 * 60 * 1000,
                    ).toLocaleDateString('en-IN', { timeZone: 'Asia/Kolkata' })}
                  </td>
                </td>
              </tr>
            </tbody>
          </table>
          <Modal className="custom-modal" show={showCarousel} onHide={handleCloseCarousel}>
            <Modal.Header className="header-m">
              <button type="button" className="cross" onClick={handleCloseCarousel}>
                x
              </button>
            </Modal.Header>
            <Modal.Body>
              <Carousel
                activeIndex={currentImageIndex}
                onSelect={(selectedIndex) => setCurrentImageIndex(selectedIndex)}
              >
                {[user.image, user.image1, user.image2, user.image3, user.image4].map((img) => (
                  <Carousel.Item key={img}>
                    <img className="" src={`https://ooh.warburttons.com/${img}`} alt="Slide" />
                  </Carousel.Item>
                ))}
              </Carousel>
            </Modal.Body>
          </Modal>

          {isModalOpen && (
            <div style={modalStyles.overlay}>
              <div style={modalStyles.modal}>
                <button
                  onClick={closeModal}
                  type="button"
                  className="primary mb-2"
                  style={modalStyles.closeButton}
                >
                  x
                </button>
                <img src={selectedImage} alt="Preview" style={modalStyles.image} />
              </div>
            </div>
          )}
        </div>
      </div>

      <h2 style={{ marginTop: '50px' }}>History</h2>
      {user.histories.map((hist) => (
        <div style={{ background: '#fff', borderRadius: '10px', marginTop: '20px' }}>
          <div className="p-4">
            <div className="d-flex flex justify-content-between">
              <div>
                <h4>
                  <span>Site Name :</span> {hist.site_name}
                </h4>
                <h4>
                  <span>Code :</span> {hist.code}
                </h4>
              </div>
              <div>
                {' '}
                <button
                  type="button"
                  onClick={exportHistoriesToExcel}
                  className="btn btn-primary mb-3 me-2"
                >
                  Export to Excel
                </button>
                <button type="button" onClick={printTables} className="btn btn-secondary mb-3">
                  Print Table
                </button>
              </div>
            </div>
            <table className="table ">
              <tbody>
              <tr>
                <td width="200">
                  <h6>Zone </h6>
                </td>
                <td>: {hist.zone}</td>
                
                <td width="200">
                  <h6>City </h6>
                </td>
                <td>: {hist.city}</td>

                <td width="200">
                  <h6>Location </h6>
                </td>
                <td width="300">: {hist.location}</td>
              </tr>

              <tr>
                

               
                <td width="200">
                  <h6>State </h6>
                </td>
                <td>: {hist.state}</td>

                <td width="200">
                  <h6>Status </h6>
                </td>
                <td>: {hist.status}</td>

                <td width="200">
                  <h6>Vendor Status </h6>
                </td>
                <td>: {hist.vendor_status}</td>
              </tr>

                <tr>
                  <td width="200">
                    <h6>Length </h6>
                  </td>
                  <td>: {hist.length}</td>
                  <td width="200">
                    <h6>Width </h6>
                  </td>
                  <td>: {hist.width}</td>
                  <td width="200">
                    <h6>Total </h6>
                  </td>
                  <td>: {hist.total}</td>
                </tr>

                <tr>
                  <td width="200">
                    <h6>Lat </h6>
                  </td>
                  <td>: {hist.lat}</td>
                  <td width="200">
                    <h6>Long </h6>
                  </td>
                  <td>: {hist.long}</td>
                  <td width="200">
                    <h6>Vendor Name </h6>
                  </td>
                  <td>: {hist.vendor_name}</td>
                </tr>

                <tr>
                  <td width="200">
                    <h6>Recce Person Name </h6>
                  </td>
                  <td>: {hist.racce_person_name}</td>

                  <td width="200">
                    <h6>Recce Person Image </h6>
                  </td>
                  <td>
                    :{' '}
                    <button
                      type="button"
                      onClick={() =>
                        openModal(`https://ooh.warburttons.com/${hist.racce_person_image}`)
                      }
                      style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }} // Optional styling
                    >
                     <img
                      src={`https://ooh.warburttons.com/${hist.racce_person_image}`}
                      alt="Person"
                      width={100}
                      height={100}
                      onError={(e) => {
                        e.target.style.display = 'none'; // Hide the broken image
                        e.target.insertAdjacentHTML('afterend', '<span>NA</span>'); // Insert "NA" next to the image
                      }}
                    />
                    </button>
                  </td>
                </tr>

                <tr>
                  <td width="200" className="d-flex gap-2">
                    {hist.image ? (
                      <button
                        type="button"
                        style={{
                          border: 'none',
                          background: 'none',
                          padding: 0,
                          cursor: 'pointer',
                        }}
                        onClick={() => handleImageClick(0)}
                      >
                        <img
                          src={`https://ooh.warburttons.com/${hist.image}`}
                          alt=""
                          width={100}
                          height={100}
                        />
                      </button>
                    ) : (
                      ''
                    )}

                    {hist.image1 ? (
                      <button
                        type="button"
                        style={{
                          border: 'none',
                          background: 'none',
                          padding: 0,
                          cursor: 'pointer',
                        }}
                        onClick={() => handleImageClick(1)}
                      >
                        <img
                          src={`https://ooh.warburttons.com/${hist.image1}`}
                          alt=""
                          width={100}
                          height={100}
                        />
                      </button>
                    ) : (
                      ''
                    )}
                    {hist.image2 ? (
                      <button
                        type="button"
                        style={{
                          border: 'none',
                          background: 'none',
                          padding: 0,
                          cursor: 'pointer',
                        }}
                        onClick={() => handleImageClick(2)}
                      >
                        <img
                          src={`https://ooh.warburttons.com/${hist.image2}`}
                          alt=""
                          width={100}
                          height={100}
                        />
                      </button>
                    ) : (
                      ' '
                    )}
                    {hist.image3 ? (
                      <button
                        type="button"
                        style={{
                          border: 'none',
                          background: 'none',
                          padding: 0,
                          cursor: 'pointer',
                        }}
                        onClick={() => handleImageClick(3)}
                      >
                        <img
                          src={`https://ooh.warburttons.com/${hist.image3}`}
                          alt=""
                          width={100}
                          height={100}
                        />
                      </button>
                    ) : (
                      ''
                    )}

                    {hist.image4 ? (
                      <button
                        type="button"
                        style={{
                          border: 'none',
                          background: 'none',
                          padding: 0,
                          cursor: 'pointer',
                        }}
                        onClick={() => handleImageClick(4)}
                      >
                        <img
                          src={`https://ooh.warburttons.com/${hist.image4}`}
                          alt=""
                          width={100}
                          height={100}
                        />
                      </button>
                    ) : (
                      ''
                    )}

                    <td style={{ minWidth: '230px' }}>Image Updated Date :</td>
                    <td style={{ minWidth: '230px' }}>
                      {new Date(
                        new Date(hist.image_last_updated_date).getTime() + 5.5 * 60 * 60 * 1000,
                      ).toLocaleDateString('en-IN', { timeZone: 'Asia/Kolkata' })}
                    </td>
                  </td>
                </tr>
              </tbody>
            </table>
            <Modal className="custom-modal" show={showCarousel} onHide={handleCloseCarousel}>
              <Modal.Header className="header-m">
                <button type="button" className="cross" onClick={handleCloseCarousel}>
                  x
                </button>
              </Modal.Header>
              <Modal.Body>
                <Carousel
                  activeIndex={currentImageIndex}
                  onSelect={(selectedIndex) => setCurrentImageIndex(selectedIndex)}
                >
                  {[hist.image, hist.image1, hist.image2, hist.image3, hist.image4].map((img) => (
                    <Carousel.Item key={img}>
                      <img className="" src={`https://ooh.warburttons.com/${img}`} alt="Slide" />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      ))}
    </>
  );
};

export default ProjectDetails;
