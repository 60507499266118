import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import ReactTable from 'react-table-v6';
import { Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { Edit } from 'react-feather';
import 'react-table-v6/react-table.css';
import ComponentCard from '../../components/ComponentCard';

const Vendor = () => {
  const { vendorName } = useParams();
  console.log(vendorName, 'kkk');
  const [modal, setModal] = useState(false);
  const [obj, setObj] = useState({});
  const [projects, setProjects] = useState([]);
  const user = JSON.parse(localStorage.getItem('user'));
  const isAdmin = user?.type === 'admin' || user?.type === 'management';
  const toggle = () => {
    setModal(!modal);
  };
  const navigate = useNavigate();

  // Function to fetch projects from the API
  const fetchProjects = async () => {
    try {
      const response = await axios.get(
        `https://ooh.warburttons.com/api/vendor-project/${vendorName}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      setProjects(response.data.data);
    } catch (error) {
      console.log('Error fetching projects:', error);
    }
  };

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const token = user?.token;
    console.log(storedUser, 'kk');
    if (!token) {
      navigate('/auth/loginformik');
      return;
    }

    fetchProjects();
  }, []);
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(projects);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
    XLSX.writeFile(workbook, 'users_data.xlsx');
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const { code, project, city,  state, zone, vendor, length, width, total } =
      event.target;

    const projectData = {
      code: code.value,
      project: project.value,
      city: city.value,
      // district: district.value,
      state: state.value,
      zone: zone.value,
      vendor_name: vendor.value,
      length: length.value,
      width: width.value,
      total: total.value,
    };

    const { id } = obj;

    try {
      await axios.post(`https://ooh.warburttons.com/api/update-project/${id}`, projectData, {
        headers: { 'Content-Type': 'application/json' },
      });

      // Update the local state with the new project data
      setProjects((prevProjects) =>
        prevProjects.map((proj) => (proj.id === id ? { ...proj, ...projectData } : proj)),
      );

      console.log('Project updated successfully!');
    } catch (error) {
      console.error('Error updating project:', error);
    }

    setModal(false);
  };
  const defaultFilterMethod = (filter, row) => {
    return String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase());
  };
  // Memoized table data to optimize rendering
  const tableData = useMemo(() => {
    return projects.map((prop) => ({
      ...prop,
      code: (
        <Link
          to={`/project-details/${prop.id}`}
          style={{ textDecoration: 'none', color: '#343a40' }}
        >
          {prop.code}
        </Link>
      ),
      actions: isAdmin ? (
        <div className="text-center">
          <Button
            onClick={() => {
              setObj({ id: prop.id, ...prop });
              toggle();
            }}
            color="inverse"
            size="sm"
          >
            <i className="fa fa-edit" /> <Edit />
          </Button>
        </div>
      ) : null,
    }));
  }, [projects, isAdmin]);

  return (
    <div>
      <div className="d-flex justify-content-end">
        <Button color="success mb-2 " onClick={exportToExcel}>
          Export to Excel
        </Button>
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Edit Project</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="code">Code</Label>
              <Input type="text" name="code" id="code" defaultValue={obj.code} />
            </FormGroup>
            <FormGroup>
              <Label for="project">Project</Label>
              <Input type="text" name="project" id="project" defaultValue={obj.project} />
            </FormGroup>
            <FormGroup>
              <Label for="city">City</Label>
              <Input type="text" name="city" id="city" defaultValue={obj.city} />
            </FormGroup>
            {/* <FormGroup>
              <Label for="district">District</Label>
              <Input type="text" name="district" id="district" defaultValue={obj.district} />
            </FormGroup> */}
            <FormGroup>
              <Label for="state">State</Label>
              <Input type="text" name="state" id="state" defaultValue={obj.state} />
            </FormGroup>
            <FormGroup>
              <Label for="zone">Zone</Label>
              <Input type="text" name="zone" id="zone" defaultValue={obj.zone} />
            </FormGroup>
            <FormGroup>
              <Label for="vendor">Vendor</Label>
              <Input type="text" name="vendor" id="vendor" defaultValue={obj.vendor_name} />
            </FormGroup>
            <FormGroup>
              <Label for="length">Length</Label>
              <Input type="text" name="length" id="length" defaultValue={obj.length} />
            </FormGroup>
            <FormGroup>
              <Label for="width">Width</Label>
              <Input type="text" name="width" id="width" defaultValue={obj.width} />
            </FormGroup>
            <FormGroup>
              <Label for="total">Total</Label>
              <Input type="text" name="total" id="total" defaultValue={obj.total} />
            </FormGroup>
            <FormGroup>
              <Button color="primary" type="submit">
                Save
              </Button>
              <Button color="secondary" className="ml-1" onClick={toggle}>
                Cancel
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>

      <ComponentCard title="Vendor Projects Table">
        <ReactTable
          data={tableData}
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: 'Code',
              accessor: 'code',
              filterMethod: (filter, row) => {
                const rowValue = row[filter.id].props.children; // Extracting the text from the Link
                return rowValue.toLowerCase().includes(filter.value.toLowerCase());
              },
            },
            {
              Header: 'Project',
              accessor: 'project',
            },
            {
              Header: 'City',
              accessor: 'city',
            },
            // {
            //   Header: 'District',
            //   accessor: 'district',
            // },
            {
              Header: 'State',
              accessor: 'state',
            },
            {
              Header: 'Zone',
              accessor: 'zone',
            },
            {
              Header: 'Vendor Name',
              accessor: 'vendor_name',
            },
            {
              Header: 'Length',
              accessor: 'length',
            },
            {
              Header: 'Width',
              accessor: 'width',
            },
            {
              Header: 'Total',
              accessor: 'total',
            },
            { Header: 'Actions', accessor: 'actions', sortable: false, filterable: false },
          ]}
          defaultPageSize={100}
          pageSizeOptions={[10, 20, 100, 200, 500, 2500]}
          showPaginationBottom
          className="-striped -highlight"
          filterable
          defaultFilterMethod={defaultFilterMethod}

        />
      </ComponentCard>
    </div>
  );
};

export default Vendor;
