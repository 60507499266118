import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';


const ProjectAdminDetails = () => {
  const { sitecode } = useParams();
  console.log(sitecode,'kkkkkkkkkkkkkkk')
  const [user, setUser] = useState(null);


  

 

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`https://ooh.warburttons.com/api/site/${sitecode}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setUser(response.data.data);
        console.log(response.data.data,'sdddddew')

      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUser();
  }, [sitecode]);




 

 

  
  if (!user) return <div>Loading...</div>;

  return (
    <>
      <div style={{ background: '#fff', borderRadius: '10px' }}>
        <div className="p-4">
          <div className="d-flex flex justify-content-between">
            <div>
              <h2>
                <span>Site Name :</span> {user.location}
              </h2>
              <h2>
                <span>Code :</span> {user.site_code}
              </h2>
            </div>
           
          </div>
          <table className="table ">
            <tbody>
              <tr>
                <td width="200">
                  <h6>Zone </h6>
                </td>
                <td>: {user.zone}</td>
                <td width="200">
                  <h6>Traffic Movement </h6>
                </td>
                <td>: {user.traffic_movement}</td>
                <td width="200">
                  <h6>City </h6>
                </td>
                <td>: {user.city}</td>
              </tr>

              <tr>
                <td width="200">
                  <h6>Site Facing </h6>
                </td>
                <td>: {user.site_facing}</td>

                <td width="200">
                  <h6>Media Type </h6>
                </td>
                <td>: {user.media_type}</td>
                <td width="200">
                  <h6>Unit </h6>
                </td>
                <td>: {user.unit}</td>
              </tr>

              <tr>
                <td width="200">
                  <h6>Total sqr ft </h6>
                </td>
                <td>: {user.total_sqr_ft}</td>
                <td width="200">
                  <h6>Width </h6>
                </td>
                <td>: {user.width}</td>
                <td width="200">
                  <h6>Total </h6>
                </td>
                <td>: {user.total}</td>
              </tr>

              <tr>
                <td width="200">
                  <h6>Lat </h6>
                </td>
                <td>: {user.lat}</td>
                <td width="200">
                  <h6>Long </h6>
                </td>
                <td>: {user.long}</td>

                <td width="200">
                  <h6>Vendor Name </h6>
                </td>
                <td>: {user.vendor}</td>
              </tr>

              <tr>
                <td width="200">
                  <h6>Illumination </h6>
                </td>
                <td>: {user.Illumination}</td>

                <td width="200">
                  <h6>Duration </h6>
                </td>
                <td>: {user.duration}</td>
                <td width="200">
                  <h6>Printing Status </h6>
                </td>
                <td>: {user.printing_status}</td>
              </tr>





              
              <tr>
                <td width="200">
                  <h6>printing_vendor </h6>
                </td>
                <td>: {user.printing_vendor}</td>

                <td width="200">
                  <h6>printing_vendor_contact </h6>
                </td>
                <td>: {user.printing_vendor_contact}</td>
                <td width="200">
                  <h6>flex_dispatched_status </h6>
                </td>
                <td>: {user.flex_dispatched_status}</td>
              </tr>
              <tr>
                <td width="200">
                  <h6>vendor_contact </h6>
                </td>
                <td>: {user.vendor_contact}</td>

                <td width="200">
                  <h6>actual_installations_date </h6>
                </td>
                <td>: {user.actual_installations_date}</td>
              
              </tr>

           
            </tbody>
          </table>
         

          
        </div>
      </div>

   
    </>
  );
};

export default ProjectAdminDetails;
